import React, {useContext, useEffect} from 'react'

import BackSvg from '../images/svgs/back.svg'
import {CartContext} from '../components/gallery/cart/CartProvider'
import CartItem from '../components/gallery/cart/CartItem'
import CheckoutIcon from '../images/svgs/checkout.svg'
import GalleryIcon from '../images/svgs/gallery.svg'
import {Link} from 'gatsby'
import SEO from '../components/shared/seo'
import {formatPrice} from '../components/shared/utils'
import {navigate} from 'gatsby'
import {useLayoutValue} from '../components/ui/layoutProvider'

const Cart = () => {
  const {cart, count, mode, total, toggle} = useContext(CartContext)
  const {state, dispatch} = useLayoutValue()

  useEffect(() => {
    dispatch({
      type: 'SET_BORDER',
      value: false,
    })

    return () => {
      dispatch({
        type: 'SET_BG',
        value: 'bg-main',
      })
    }
  }, [])
  return (
    <>
      <SEO
        title="Shop | John Dore"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />
      <div className="flex bg-main w-full self-center  flex-col  rounded">
        <div className=" text-white flex flex-row justify-between  w-full  bg-gray tracking-widest">
          <div className="bg-gray pt-20 mx-10 text-4xl md:text-7xl pt-20 leading-header">cart</div>
          <button
            className="m-2 mx-4 self-end"
            onClick={() => {
              navigate(-1)
            }}>
            <BackSvg className="h-8" />
          </button>
        </div>
        {count === 0 && (
          <>
            <div className="px-10 text-2xl md:text-3xl text-black italic mt-12 ">
              Your cart is currently empty...
            </div>
            <div className="border-b border-white w-full my-6"></div>
          </>
        )}

        {count === 0 && (
          <Link
            className=" rounded px-10 w-content text-black mb-8 font-medium text-sm hover:underline"
            to="/gallery">
            Go to gallery
            <GalleryIcon className="inline-block ml-2 h-8 w-8" />
          </Link>
        )}
        <div className="m-10 ">
          {cart.map(([sku, quantity]) => (
            <>
              <div className="border-t border-b border-white my-10">
                <CartItem key={sku.id} sku={sku} quantity={quantity} />
              </div>
            </>
          ))}
        </div>
        {count != 0 && (
          <>
            <div className="border-b border-white w-full  mb-6"></div>
            <div className="flex flex-row self-start text-left">
              <div className="pl-10 text-sm italic">Subtotal</div>
              <div className="font-mono px-6">{formatPrice(total, 'gbp')}</div>
              <div className="italic text-xs px-2">not including shipping cost</div>
            </div>
            <div className="border-b border-white w-full mt-6"></div>
            {/* <div className="flex flex-col font-medium  text-xs align-center"> */}

            <Link
              className=" text-black mx-10  w-content  mt-8 font-bold text-sm hover:underline "
              to="/checkout">
              Checkout <CheckoutIcon className="inline-block w-8 h-8" />
            </Link>
            <Link
              className=" rounded mx-10 w-content text-black mb-8 font-medium text-sm hover:underline"
              to="/gallery">
              Continue Shopping <GalleryIcon className="inline-block ml-2 h-8 w-8" />
            </Link>
            {/* </div> */}
          </>
        )}
      </div>
    </>
  )
}
export default Cart
